let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-evolution-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-evolution-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://gkp4xck2kfdodhwkdt3vntbw7q.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://xzgrfawjq1.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://cvijd1u1yl.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.evolution.forwoodsafety.com",
        WEBSOCKET: "wss://n85utmo5h5.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_ivGzssITx",
        APP_CLIENT_ID: "28v1300qopcf3fs2u3892b6tci",
        IDENTITY_POOL_ID: "us-west-2:6ffde9ba-ea1f-4704-8c08-47a377daf3c3",
        USERPOOL_HOSTED_DOMAIN: "forwood-evolution-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.evolution.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.evolution.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::072306365335:role/prod-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: 'Evolution',
      SUPPORTED_LIST: 'Evolution,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.5",
        HOSTNAME: "id.evolution.forwoodsafety.com",
        COOKIE_DOMAIN: ".evolution.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.evolution.forwoodsafety.com"
    }
};

export default config;
